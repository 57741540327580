.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10rem;
    gap: 2rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 6rem;
}

.content button {
    width: fit-content;
}

.content p {
    max-width: 450px;
}

.img {
    width: 500px;
}

@media screen and (max-width: 920px) {
    .container {
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        padding: 0 2rem;
    }

    .img {
        width: 300px;
    }
}

@media screen and (max-width: 390px) {
    .img {
        width: 100%;
    }
}