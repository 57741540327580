.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.container h1 {
    font-size: 10vw;
}

.container p {
    font-size: 5vw;
}