/* ======= DESKTOP HEADER (UNCHANGED) ======= */
.mainHeader {
    display: flex;
    position: fixed;
    gap: 1rem;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px;
    background: rgba(220, 220, 220, 0.55);
    backdrop-filter: blur(15px);
    padding: 16px 37px;
    z-index: 100;
}

.mobileMenu {
    display: none;
    position: static;
    width: auto;
    height: auto;
    clip-path: none;
    box-shadow: none;
}

.partWrapper {
    display: flex;
}

.cleanBtn {
    background: none;
    border: none;
    color: #181818;
    font-size: 16px;
    font-weight: 500;
    width: 5rem;
}

.cleanBtn p {
    padding-top: 2px;
    pointer-events: auto;
    cursor: pointer;
    display: inline-block;
    border-bottom: solid 2px transparent;
    transition: 0.3s;
}

.cleanBtn p:hover {
    border-bottom: solid 2px #181818;
}

.logoImg {
    width: 50px;
}

@media screen and (max-width: 650px) {
    .partWrapper {
        display: none;
    }

    .mainHeader {
        display: flex;
        justify-content: space-between;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        padding: 12px 24px;
        top: 1rem;
    }

    .logoContainer {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .logoImg {
        width: 45px;
        height: auto;
    }

    .companyName {
        font-size: 18px;
        font-weight: bold;
        color: #181818;
    }

    .burgerBtn {
        background: none;
        border: none;
        cursor: pointer;
    }

    .burgerBtn img {
        width: 40px;
        height: 40px;
    }

    /* ======= MOBILE MENU (FINAL ANIMATION) ======= */
    .mobileMenu {
        position: fixed;
        top: 0;
        right: 0;
        width:60%;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 100;
        clip-path: polygon(100% 0, 100% 0, 0% 100%, 100% 85%);
        box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.2);
    }

    .closeBtn {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        font-size: 16px;
        background: none;
        border: none;
        cursor: pointer;
    }

    .menuList {
        list-style: none;
        padding: 0;
        text-align: center;
        margin-top: 10rem;
        margin-right: 2rem;
    }

    .menuItem {
        margin: 10px 0;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        cursor: pointer;
        color: #000;
        text-align: right;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}