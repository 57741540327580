.privacy {
    margin-top: 10rem;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 5rem;
}

@media screen and (max-width: 820px) {
    .privacy {
        padding: 1rem;
    }
}