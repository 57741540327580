.primaryArrowBtn {
    display: flex;
    padding: 11px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #181818;
    color: white;
    width: fit-content;
    margin-left: auto;
    cursor: pointer;
    border: none;
    transition: 0.3s;
}

.primaryArrowBtn:hover {
    background: #3B3B3B;
}

@media screen and (max-width: 600px) {
    .primaryArrowBtn {
        font-size: 1rem;
    }
}