.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background: #181818;
    width: 450px;
    height: 170px;
    padding: 25px;
    border-radius: 20px;
}

.container img {
    width: 60px;
}

.inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p {
    font-size: 1rem;
    color: white;
}

.container button {
    margin-left: auto;
}

@media screen and (max-width: 500px) {
    .container {
        width: 90%;
        height: 150px;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
    }

    .container img {
        width: 55px;
    }

    .p {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 410px) {
    .container {
        height: 130px;
    }

    .container img {
        width: 50px;
    }

    .p {
        font-size: 0.75rem;
    }
}