.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1728px;
    margin: 0 auto;
    margin-top: 9rem;
    padding: 0 5rem;
    width: 100%;
}

.contact p {
    width: 100%;
    text-align: left;
}

.contactHeading {
    max-width: 800px;
    align-self: flex-start;
    text-align: left;
}

/* The form is a row with two columns (left & right) */
.form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 5rem;
    gap: 5rem; /* space between columns */
}

/* Left column */
.left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Right column */
.right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Let inputs fill the column width */
.left input,
.right input {
    width: 100%;
}

/* Textarea fills the width, with a fixed height */
.right textarea {
    width: 100%;
    height: 150px;
}

/* Button alignment—adjust as you wish */
.primary-btn {
    align-self: flex-start;  /* or 'flex-end' if you want it on the right */
    margin-top: 1rem;
}

.fit {
    width: fit-content;
    margin-left: auto;
}

@media screen and (max-width: 840px) {
    .contact {
        padding: 0 2rem;
    }

    .form {
        flex-direction: column;
        gap: 2rem;
    }

    .left,
    .right {
        width: 100%;
    }

    .primary-btn {
        align-self: center;
    }
}