.btn {
    border: none;
    cursor: pointer;
    background: transparent;
}

@media screen and (max-width: 350px) {
    .btn img {
        width: 2rem;
    }
}