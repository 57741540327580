.servicesContainer {
    max-width: 1728px;
    width: 100%;
    margin: 10rem auto 0 auto;
    padding: 0 5rem;
}

.servicesContainer p {
    text-align: left;
}

.servicesTitle {
    max-width: 800px;
    text-align: left;
}

.serviceRow,
.serviceRowReverse {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10rem;
}

.serviceRow {
    flex-direction: row;
}

.serviceRowReverse {
    flex-direction: row-reverse;
}

.textBlock {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 600px;
}

.textBlock h3 {
    font-size: 48px;
    font-weight: 700;
}

.contentBox {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.sectionHeading {
    color: #3B3B3B;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.serviceImage {
    width: 420px;
    object-fit: cover;
}

@media screen and (max-width: 1420px) {
    .serviceRow,
    .serviceRowReverse {
        gap: 5rem;
    }

    .textBlock h3 {
        font-size: 36px;
    }

    .serviceImage {
        width: 350px;
    }

    .servicesContainer {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 875px) {
    .serviceRow,
    .serviceRowReverse {
        flex-direction: column;
        gap: 5rem;
    }

    .serviceImage {
        width: 100%;
    }

    .textBlock h3 {
        font-size: 32px;
    }

    .servicesContainer {
        padding: 0 2rem;
    }

    .contentBox {
        gap: 0.2rem;
    }

    .servicesContainer {
        margin-top: 10rem;
    }

    .sectionHeading {
        font-size: 1.4rem;
    }
}