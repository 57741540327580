.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 53px;
    padding: 29px;
    margin-top: 5px;
}

.header_wrapper {
    display: flex;
    gap: 10px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mainSettings {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.generalInformation {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    gap: 10px;
}