.about {
    margin-top: 10rem;
}

.firstSection {
    position: fixed;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    min-height: 70vh;
    width: 90%;
}

.firstSectionLeft {
    width: 50%;
    padding-right: 5%;
    /* Removed the border from here */
}

.borderElement {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-1px);
    width: 2px;
    background-color: #181818;
}

.firstSectionRight {
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2rem;
}

.firstSectionLeft h1 {
    max-width: 800px;
}

.firstSectionLeft p {
    margin-left: 0.5rem;
}

.firstSectionRight p {
    text-align: right;
}

.personSectionRight {
    margin-top: 160vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.personSectionRightText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2rem;
    margin-bottom: 3rem;
}

.personSectionRightText p,
.personSectionRightText h2 {
    text-align: right;
}

.kristiyan {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 2rem;
}

.kristiyanBio {
    max-width: 600px;
}

.erica {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}

.role {
    font-size: 1.2rem;
}

.personSectionLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 15rem;
}

.personSectionLeft img,
.personSectionRight img {
    width: 400px;
}

.personSectionLeftText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2rem;
    margin-bottom: 3rem;
}

.ourMission {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 10rem;
}

@media screen and (max-width: 1200px) {

    .kristiyan h2,
    .erica h2{
        font-size: 2.5rem;
    }

    .kristiyan p,
    .erica p {
        font-size: 1.5rem;
        max-width: 500px;
    }
}

@media screen and (max-width: 1000px) {
    .kristiyan p,
    .erica p {
        font-size: 1.2rem;
        max-width: 400px;
    }
}

@media screen and (max-width: 920px) {
    .borderElement {
        display: none;
    }

    .firstSection {
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
    }

    .firstSectionLeft {
        width: 80%;
        padding-right: 0;
    }

    .firstSectionRight {
        width: 80%;
        margin-top: 2rem;
        margin-left: auto;
    }

    .kristiyan {
        align-items: flex-start;
    }

    .personSectionRight {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 120vh;
    }

    .personSectionRightText {
        padding: 0 2rem;
        max-width: 400px;
        gap: 0;
    }

    .personSectionRightText p {
        text-align: left;
    }

    .personSectionRight h1 {
        text-align: left;
    }

    .kristiyan h2 {
        text-align: left;
        font-size: 2.2rem;
        padding: 0;
        margin: 0;
    }

    .personSectionRight img {
        width: 350px;
    }

    .personSectionLeft {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .personSectionLeft img {
        width: 350px;
    }

    .personSectionLeftText {
        padding: 0 2rem;
        gap: 0;
    }

    .personSectionLeftText h1 {
        text-align: left;
    }

    .erica {
        gap: 1rem;
    }

    .ourMission {
        margin-top: 5rem;
        padding: 0 2rem;
    }
}

@media screen and (max-width: 500px) {
    .firstSection {
        padding-bottom: 3rem;
        width: 90%;
    }
    .firstSectionLeft {
        width: 90%;
        padding-right: 0;
    }

    .firstSectionRight {
        width: 90%;
        margin-top: 2rem;
        margin-left: auto;
    }

    .personSectionRight {
        margin-top: 120vh;
    }

    .personSectionLeft,
    .ourMission {
        margin-top: 10rem;
    }
}

@media screen and (max-width: 390px) {
    .firstSectionLeft {
        width: 100%;
        padding-right: 0;
    }

    .firstSectionRight {
        width: 100%;
        margin-top: 2rem;
        margin-left: auto;
    }
}