.adminLayout {
    min-height: 100dvh;
}

.navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    width: 60px;
    padding: 20px 40px;
    height: 100dvh;
    background: #181818;
    box-sizing: border-box; /* Include padding in the width */
}

.content {
    margin-left: 60px; /* Start content right after the navbar */
    width: calc(100% - 60px); /* Fill the remaining width */
}

.logo {
    width: 50px;
}

.link {
    display: grid;
    place-items: center;
    background: none;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 100px;
    border: solid 2px transparent;
    transition: 0.3s;
    cursor: pointer;
}

.link:hover {
    border: solid 2px #fff;
}

.link img {
    object-fit: contain;
    width: 100%;
}

.navbarLinks {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.activeLink {
    background: white;
    color: black;
}

.activeLink img {
    filter: invert(1);
}
