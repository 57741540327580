.getOffer {
    display: flex;
    justify-content: center;
    padding: 0 2rem;
    margin-top: 10rem;
    min-height: 50vh;
}

.step {
    margin-bottom: 3rem;
    min-width: 700px;
}

.step h2 {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 1.2;
}

/* STEP 1: Services grid */
.services {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.service {
    border-radius: 8px;
    background: rgba(220, 220, 220, 0.55);
    display: flex;
    padding: 12px 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.service:hover {
    background: rgba(220, 220, 220, 0.85);
}

.serviceName {
    font-size: 1rem;
    font-weight: 600;
}

.selected {
    width: 16px;
    height: 16px;
    background-color: #000;
    border-radius: 50%;
}

.unselected {
    width: 16px;
    height: 16px;
    border: 2px solid #000;
    border-radius: 50%;
}

.formFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: 800px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.formFields > :nth-child(5),
.formFields > :nth-child(6) {
    grid-column: 1 / 3;
}

input.primary-input,
textarea.primary-textarea {
    width: 100%;
    padding: 0.6rem;
    border: 2px solid #333;
    border-radius: 4px;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
}

.formGroup textarea{
    min-height: 200px;
}

.buttonRow {
    display: flex;
    justify-content: space-between;
}

.buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.buttons button {
    margin-left: auto;
}

.thankYou {
    font-size: 1.25rem;
    line-height: 1.4;
}

@media screen and (max-width: 800px) {
    .step {
        min-width: 100%;
    }

    .formFields {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 600px) {
    .step2 {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .step2 .formFields {
        display: flex !important;
        flex-direction: column !important;
        gap: 1rem; /* adjust as needed */
    }
}