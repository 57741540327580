.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 20px;
    margin-top: 25dvh;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form input {
    min-width: 300px;
}