.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 50px 50px 0px 0px;
    padding: 58px 100px;
    background: #181818;
    margin-top: 10rem;
    height: 400px;
}

.footerLeft,
.footerRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footerLeftTop {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footerLeftTop img {
    width: 70px;
}

.footerLeftTop p {
    max-width: 290px;
}

.footerTopRight {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    gap: 2rem;
}

.footerBtn {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
}

.footerBottomRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
}

.footerSmallBtn {
    color: #BDBDBD;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
}

.insta {
    cursor: pointer;
}

.footerColor {
    color: #BDBDBD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 900px) {

    .footer {
        flex-direction: column;
        padding: 58px 50px;
        align-items: flex-start;
    }

    .footerLeft {
        gap: 2rem;
    }

    .footerTopRight {
        display: none;
    }
}