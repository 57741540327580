@import url('https://fonts.cdnfonts.com/css/satoshi');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: 'Satoshi', sans-serif;
}

.container {
    max-width: 1728px;
    margin: 0 auto;
    padding: 0 5rem;
}

h1 {
    color: #181818;
    font-size: 64px;
    font-weight: 900;
}

h2 {
    color: #181818;
    font-size: 48px;
    font-weight: 900;
}

h3 {
    color: #000;
    font-size: 32px;
    font-weight: 500;
}

h4 {
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
}

.primary-btn {
    display: flex;
    padding: 15px 45px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #181818;
    color: white;
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.primary-btn:hover {
    background: #3B3B3B;
}

.secondary-btn {
    padding: 12px 40px;
    border-radius: 100px;
    background: #EBEBEB;
    color: #181818;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s;
    border: none;
}

.underline-btn {
    border: none;
    background: transparent;
    color: #181818;
    font-size: 20px;
    font-weight: 500;
    border-bottom: solid 2px #181818;
    cursor: pointer;
}

.main-p {
    color: #3B3B3B;
    font-size: 20px;
    font-weight: 500;
}

.center-text {
    text-align: center;
}

.white-text {
    color: #FFFFFF;
}

.primary-input {
    display: flex;
    padding: 15px 26px;
    align-items: center;
    border-radius: 10px;
    border: 2.5px solid #000;
    background: #F7F7F7;
    font-size: 16px;
    font-weight: 300;
}

.primary-textarea {
    display: flex;
    padding: 11px 26px;
    align-items: center;
    border-radius: 10px;
    border: 2.5px solid #000;
    background: #F7F7F7;
    resize: none;
    font-size: 16px;
    font-weight: 300;
}

.primary-btn-exception {
    width: fit-content !important;
}

.admin__btn {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #181818;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.admin__input {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    border: 2px solid #000;
    background: #EFEFEF;
    width: 100%;
}

.admin__textarea {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    border: 2px solid #000;
    background: #EFEFEF;
    width: 100%;
    height: 125px;
    resize: none;
}

.admin_route_heading {
    font-size: 20px;
}

.admin_select {
    width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    border: 2px solid #000;
    background: #EFEFEF;
}

.admin_select:focus,
.admin__input:focus,
.admin__textarea:focus {
    outline: none;
}

.adin_label {
    color: #000;
    font-family: Satoshi;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 5px;
}

@media screen and (min-width: 1728px) {
    h1 {
        font-size: 72px;
    }

    h2 {
        font-size: 56px;
    }

    h3 {
        font-size: 36px;
    }

    h4 {
        font-size: 28px;
    }

    .container {
        padding: 0 7rem;
    }

    .main-p {
        font-size: 24px;
    }
}

@media screen and (max-width: 1440px) {
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 36px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 18px;
    }
    .container {
        padding: 0 2rem;
    }
    .primary-btn {
        padding: 10px 30px;
        font-size: 18px;
    }
    .secondary-btn {
        padding: 11px 35px;
        font-size: 18px;
    }
    .main-p,
    .underline-btn {
        font-size: 18px;
    }
    .primary-input {
        padding: 13px 24px;
    }
    .primary-textarea {
        padding: 9px 24px;
    }
}

@media screen and (max-width: 820px) {
    h1 {
        font-size: 36px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 16px;
    }
    .primary-btn {
        border-radius: 12px;
        width: 100%;
    }
    .secondary-btn {
        padding: 10px 30px;
        font-size: 16px;
    }
    .main-p,
    .underline-btn {
        font-size: 18px;
    }
    .primary-input {
        padding: 11px 20px;
    }
    .primary-textarea {
        padding: 7px 20px;
    }
}