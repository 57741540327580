.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000; /* Ensure modal is on top */
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Final dark overlay color */
    z-index: 1;
}

.container {
    position: fixed;
    right: 0;
    top: 0;
    padding: 20px 20px 35px;
    width: 600px;
    height: 100vh;       /* Fixed height equals viewport height */
    max-height: 100vh;   /* Ensure it never exceeds the viewport */
    background: #FFF;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
    overflow-y: auto;    /* Allow internal scrolling */
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading img {
    width: 15px;
    height: 15px;
}

.content {
    margin-top: 20px;
}
