.home {
    margin-top: 8rem;
}

.firstSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 70vh;
    gap: 2rem;
}

.firstSectionContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 50%;
}

.firstSection img {
    width: 100%;
    max-width: 500px;
}

.firstSectionContent p {
    line-height: 42px;
    max-width: 80%;
}

.firstSectionContent h2 span {
    color: #4A90E2;
}

.firstSection p {
    margin-top: 40px;
}

.btnsContainer {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}

.landingPartnersWrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.landingPartnersWrapper p {
    margin-top: 0;
}

.landingPartnersWrapper div {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.secondSection {
    position: relative;
    margin-top: 8rem;
}

.secondSectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.animationContainer {
    position: relative;
    width: 100%;
    overflow: visible;
    margin: 0 auto;
    z-index: -1;
}

.servicesSection {
    display: flex;
    width: 100vw;
    position: relative;
    background-color: #f0f0f0;
    padding: 2rem 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5rem;
}

.servicesContainer {
    max-width: 1728px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}

.headingWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(1rem, 5vw, 5rem);
    justify-content: center;
}

.service {
    width: 350px;
    min-height: 210px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 20px 20px 0px rgba(238, 238, 238, 0.25);
    padding: 26px 22px 35px 27px;
}

.serviceContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.webDevLottie,
.uiUxLottie,
.responsiveDesignLottie {
    width: 70%;
}

.graphicDesignLottie,
.techSupportLottie {
    width: 38%;
    padding: 1.3rem 0;
}

.hostingLottie {
    width: 50%;
    padding: 1rem 0;
}

.driveSection {
    width: 100vw;
    position: relative;
    background: #181818;
    padding: 2rem 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5rem;
    border-radius: 0;
}

.driveContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8%;
}

.driveHeadingWrapper {
    display: flex;
    flex-direction: column;
}

.driveHeadingWrapper p {
    max-width: 30rem;
    padding-left: 0.3rem;
}

.soon {
    padding-right: 2rem;
}

.offerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 10rem;
}

.offerSection h2 {
    max-width: 35rem;
    text-align: center;
}

.offerSection p {
    max-width: 30rem;
    text-align: center;
}

@media screen and (min-width: 1920px) {
    .servicesContainer {
        max-width: 1920px;
    }
    .secondSection {
        margin-bottom: 20rem;
    }

    .offerSection {
        gap: 4rem;
    }

    .offerSection h2 {
        max-width: 50rem;
        text-align: center;
    }

    .offerSection p {
        max-width: 30rem;
        text-align: center;
    }
}

@media screen and (max-width: 1230px) {
    .services {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1000px) {
    .firstSection {
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }


    .firstSection img {
        width: 100%;
        margin-top: 2rem;
    }
    .firstSectionContent {
        display: flex;
        max-width: 100%;
        gap: 3rem;
    }
    .firstSectionContent div {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
    .firstSectionContent p {
        margin: 0;
        line-height: 25px;
    }

    .landingPartnersWrapper {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .landingPartnersWrapper div {
        flex-direction: row;
        margin-top: -2rem;
    }

    .landingPartnersWrapper div img:first-child {
        margin: 0 !important;
    }

    .landingPartnersWrapper div img:nth-child(2) {
        margin-bottom: 2.2rem;
    }

    .landingPartnersWrapper div img:nth-child(3) {
        margin-bottom: 2rem;
    }

    .landingPartnersWrapper div img:nth-child(4) {
        margin-bottom: 2rem;
    }

    .btnsContainer {
        margin-top: 0;
    }
}

@media screen and (max-width: 820px) {
    .services {
        grid-template-columns: 1fr;
    }
    .secondSection {
        margin: 15rem 0 10rem 0;
    }
    .secondSectionContent {
        width: 100%;
    }
    .headingWrapper h2 {
        font-size: 1.8rem;
    }

    .driveSection {
        width: 100vw;
        position: relative;
        background-color: #181818;
        padding: 2rem 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 5rem;
        border-radius: 0;
        padding-bottom: 0;
    }
    .driveContent {
        max-width: 1728px;
        margin: 0 auto;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
    .driveLottie {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mobileDriveImage {
        width: 90%;
        margin-left: auto;
        border-top-left-radius: 20px;
    }
    .driveContentMobile {
        display: flex;
        flex-direction: column;;
        gap: 1rem;
        margin-top: 5rem;
        margin-bottom: -3rem;
    }
    .soon {
        font-weight: 800;
    }
    .driveHeadingWrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .driveHeadingWrapper p,
    .soon {
        padding: 0;
    }
    /* New mobile styles for the landing image with button overlay */
    .imageButtonWrapper {
        position: relative;
        width: 80%;
        margin: 0 auto;
    }
    .landingImage {
        width: 100%;
        display: block;
    }
    .buttonOverlay {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    .offerSection button {
        margin-top: 2rem;
    }

    .offerSection h2 {
        text-align: left;
    }

    .offerSection p {
        text-align: left;
    }
}

@media screen and (max-width: 670px) {
    .firstSection h1 {
        font-size: 2.5rem;
        width: 100%;
    }
    .headingWrapper {
        justify-content: space-between;
    }
}

@media screen and (max-width: 392px) {
    .secondSection {
        margin: 15rem 0 10rem 0;
    }
    .headingWrapper {
        justify-content: space-between;
    }
    .headingWrapper h2 {
        font-size: 1.6rem;
    }
    .service {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .headingWrapper h2 {
        font-size: 1.3rem;
    }
}
