.tools {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1728px;
    margin: 0 auto;
    margin-top: 10rem;
    padding: 0 5rem;
    width: 100%;
}

.tools p {
    width: 100%;
    text-align: left;
}

.toolsHeading {
    max-width: 800px;
    align-self: flex-start;
    text-align: left;
}

.toolsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.toolsGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* Ensures exactly two columns */
    gap: 8rem;
    margin-top: 10rem;
    justify-content: center;
    align-items: start;
    width: 100%;
    max-width: 1728px; /* Prevents excessive widening */
}


.tool {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.toolHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.toolHeading img {
    width: 115px;
}

.toolP {
    max-width: 700px;
    text-align: left;
}

.toolsSecondary {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    margin-top: 10rem;
}

.toolsSecondary h1,
.toolsSecondary p {
    text-align: center;
}

.toolsSecondary p {
    max-width: 600px;
}

.fit {
    width: fit-content;
}

@media (min-width: 1920px) {
    .tools {
        max-width: 1920px;
        padding: 0 10rem;
    }

    .toolsGrid {
        grid-template-columns: repeat(2, minmax(0, 1fr)); /* FORCE ONLY 2 COLUMNS */
        gap: 8rem;
    }
}

@media screen and (max-width: 970px) {
    .toolsGrid {
        grid-template-columns: repeat(1, minmax(0, 1fr)); /* FORCE ONLY 1 COLUMN */
        gap: 4rem;
    }

    .tools {
        padding: 0 2rem;
    }

    .toolsSecondary {
        margin-top: 5rem;
    }

    .toolHeading img {
        width: 80px;
    }
}